import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Popups } from 'components';
import useAppNavigation from 'hooks/useAppNavigation';
import { useTheme } from 'hooks/useTheme';
import { AppLayout } from 'layouts/AppLayout';
import { MainLayout } from 'layouts/MainLayout';
import {
  Chatbot,
  Chatbots,
  Chats,
  CreateChatbot,
  Dashboard,
  Login,
  Plugin,
  Profile,
  Settings,
  Support,
} from 'pages';
import { PageWrapper } from 'ui-elements';

function App() {
  const navigate = useNavigate();
  const { toggleTheme } = useTheme();

  const { getPath } = useAppNavigation();

  useEffect(() => {
    if (getPath().pathname === '/') navigate('/login');
  }, [getPath().pathname]);

  console.log("test");

  return (
    <AppLayout>
      <PageWrapper>
        <Routes>
          <Route path="login/" element={<Login />} />
          <Route path="create-chatbot/:id/" element={<CreateChatbot />} />
          <Route element={<MainLayout />}>
            <Route path="chatbots/" element={<Chatbots />} />
            <Route path="chatbots/:id/*" element={<Chatbot />}>
              <Route path="chatbots/:id/dashboard/" element={<Dashboard />} />
              <Route path="chatbots/:id/chats/:chatId" element={<Chats />} />
              <Route path="chatbots/:id/settings/" element={<Settings />} />
              <Route path="chatbots/:id/plugin/" element={<Plugin />} />
            </Route>
            <Route path="profile/" element={<Profile />} />
            <Route path="support/" element={<Support />} />
          </Route>
        </Routes>
      </PageWrapper>
      <ToastContainer />
      <Popups />
    </AppLayout>
  );
}

export default App;
