import {
  BellIcon,
  InformationCircleIcon,
  MoonIcon,
  SunIcon,
} from '@heroicons/react/24/outline';
import React from 'react';

import { useTheme } from 'hooks/useTheme';

const settings = [
  {
    id: 'info',
    icon: InformationCircleIcon,
  },
  {
    id: 'notifications',
    icon: BellIcon,
  },
  {
    id: 'theme',
    icon: MoonIcon,
    dark: SunIcon,
  },
];

export const Settings = () => {
  const { toggleTheme, isDarkMode } = useTheme();

  return (
    <div className="flex flex-col items-center gap-5">
      {settings.map((setting) => (
        <button
          key={setting.id}
          onClick={setting.id === 'theme' ? toggleTheme : setting.onClick}
        >
          {isDarkMode && setting.id === 'theme' ? (
            <setting.dark className="min-h-6 min-w-6" />
          ) : (
            <setting.icon className="min-h-6 min-w-6" />
          )}
        </button>
      ))}
    </div>
  );
};
