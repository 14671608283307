const generateId = () => {
  return Math.ceil(Math.random() * 10);
};

export const generateUniqueId = (existingIds) => {
  let id = generateId();
  console.log('ZALUPA', existingIds, id);
  while (existingIds.includes(id)) {
    id = generateId();
  }
  return id;
};
