export const formatSymbols = (symbols) => {
  if (symbols >= 1000000) {
    return `${(symbols / 1000000).toFixed(1)}M`;
  } else if (symbols >= 1000) {
    return `${(symbols / 1000).toFixed(0)}K`;
  } else {
    return `${symbols}`;
  }
};

export const convertBase64ToArrayBuffer = (base64) => {
  const binaryString = atob(base64);
  const buffer = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    buffer[i] = binaryString.charCodeAt(i);
  }
  return buffer;
};
