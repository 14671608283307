import { useSelector } from 'react-redux';

import { useWebSocket } from 'context/WebSocketContext';
import useAppNavigation from 'hooks/useAppNavigation';

const useStripe = () => {
  const { sendRequest } = useWebSocket();
  const { getPath } = useAppNavigation();
  const { chatbotId } = useSelector((state) => state.chatbot);
  const chatbotIdFromPath = getPath().pathArray[2];
  const { activeSubscriptions } = useSelector(
    (state) => state.stripe,
  );

  const filteredSubs = activeSubscriptions?.filter(
    (subscription) =>
      subscription.status === 'active' ||
      subscription.status === 'trialing',
  );

  const currentChatbotId = chatbotId || chatbotIdFromPath;

  const createCheckoutSession = (priceName, botId, trialWish = false) => {
    const url = window.location.href;
    sendRequest('stripe/createCheckoutSession', {
      url,
      priceName,
      chatbotId: botId || currentChatbotId,
      currentPayedPrices: filteredSubs.map(subscription => subscription.metadata.priceName),
      trialWish
    }).then((result) => {
      window.open(result.url, '_current');
    });
  };

  const renewSubscription = ({ subscriptionId, trialWish = false }) => {
    return sendRequest('stripe/renewSubscription', { subscriptionId, trialWish });
  };

  const cancelSubscription = ({ subscriptionId }) => {
    return sendRequest('stripe/cancelSubscription', { subscriptionId });
  };

  const changeSubscriptions = ({ subscriptionId, priceName }) => {
    return sendRequest('stripe/changeSubscriptions', {
      subscriptionId,
      priceName
    });
  };

  const createPortalSession = () => {
    const url = window.location.href;
    sendRequest('stripe/createPortalSession', {
      url,
    }).then((result) => {
      window.open(result.url, '_current');
    });
  };

  return {
    createCheckoutSession,
    cancelSubscription,
    changeSubscriptions,
    createPortalSession,
    renewSubscription,
  };
};

export default useStripe;
