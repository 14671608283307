import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { arrayMove } from '@dnd-kit/sortable';

import { setLeadsForm } from '../redux/slices/chatbotPreview/leadsSlice';

export const useSortDrag = () => {
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const { leadsForm } = useSelector((state) => state.leadsSlice);

  const handleDragStart = (e, title) => {
    e.dataTransfer.setData('cardTitle', title);
  };

  // const handleDragEnd = (e) => {
  //   const cardTitle = e.dataTransfer.getData('cardTitle');

  //   setActive(false);
  //   clearHighlights();

  //   const indicators = getIndicators();
  //   const { element } = getNearestIndicator(e, indicators);

  //   const before = element.dataset.before || '-1';

  //   if (before !== cardTitle) {
  //     let copy = [...leadsForm];

  //     let cardToTransfer = copy.find((c) => c.title === cardTitle);
  //     if (!cardToTransfer) return;
  //     cardToTransfer = { ...cardToTransfer };

  //     copy = copy.filter((c) => c.title !== cardTitle);

  //     const moveToBack = before === '-1';

  //     if (moveToBack) {
  //       copy.push(cardToTransfer);
  //     } else {
  //       const insertAtIndex = copy.findIndex((el) => el.title === before);
  //       if (insertAtIndex === undefined) return;

  //       copy.splice(insertAtIndex, 0, cardToTransfer);
  //     }

  //     dispatch(setLeadsForm(copy));
  //   }
  // };

  const sortList = (items, active, over) => {
    const oldIndex = items.findIndex((item) => item.id === active.id);
    const newIndex = items.findIndex((item) => item.id === over.id);

    return arrayMove(items, oldIndex, newIndex);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    console.log('active over DragEnd', active, over);

    if (over && active.id !== over.id) {
      const sortedForm = sortList(leadsForm, active, over);
      dispatch(setLeadsForm(sortedForm));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    highlightIndicator(e);

    setActive(true);
  };

  const clearHighlights = (els) => {
    const indicators = els || getIndicators();

    indicators.forEach((i) => {
      i.style.opacity = '0';
    });
  };

  const highlightIndicator = (e) => {
    const indicators = getIndicators();

    clearHighlights(indicators);

    const el = getNearestIndicator(e, indicators);

    el.element.style.opacity = '1';
  };

  const getNearestIndicator = (e, indicators) => {
    const DISTANCE_OFFSET = 50;

    const el = indicators.reduce(
      (closest, child) => {
        const box = child.getBoundingClientRect();

        const offset = e.clientY - (box.top + DISTANCE_OFFSET);

        if (offset < 0 && offset > closest.offset) {
          return { offset: offset, element: child };
        } else {
          return closest;
        }
      },
      {
        offset: Number.NEGATIVE_INFINITY,
        element: indicators[indicators.length - 1],
      },
    );

    return el;
  };

  const getIndicators = () => {
    return Array.from(document.querySelectorAll('[data-column="leads"]'));
  };

  const handleDragLeave = () => {
    clearHighlights();
    setActive(false);
  };

  return {
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleDragLeave,
    active,
  };
};
