import { useDispatch, useSelector } from 'react-redux';

import { showToastError } from 'components/CustomToast';
import { saveImageToStorageAndGetUrl } from 'utils/firebaseHelper';

import {
  setBubbleIcon,
  setCompanyLogo,
} from '../redux/slices/chatbotPreview/designSlice';
import useAppNavigation from './useAppNavigation';
import { useUpdateBot } from './useUpdateBot';

const useImageUpload = () => {
  const dispatch = useDispatch();
  const { getPath } = useAppNavigation();
  const { updateBotCompanyLogo, updateBotBubbleIcon } = useUpdateBot();
  const { user } = useSelector((state) => state.user);
  const { chatbotId } = useSelector((state) => state.chatbot);
  const chatbotIdFromPath = getPath().pathArray[2];
  const botId = chatbotId || chatbotIdFromPath;

  const uploadCompanyLogo = async (file) => {
    try {
      const image = await imageReader(file);

      const fileNameParts = image.name.split('.');
      const extension = fileNameParts.pop();

      const fileName = `companyLogo.${extension}`;
      const imageURL = await saveImageToStorageAndGetUrl(
        image.base64String,
        image.type,
        fileName,
        user.email,
        botId,
      );
      updateBotCompanyLogo(botId, imageURL.downloadURL);
      dispatch(setCompanyLogo(imageURL.downloadURL));
    } catch (err) {
      console.log(err);
      showToastError(err.name);
    }
  };

  const uploadBubbleIcon = async (file) => {
    try {
      const image = await imageReader(file);

      const fileNameParts = image.name.split('.');
      const extension = fileNameParts.pop();

      const fileName = `chatBubbleIcon.${extension}`;
      const imageURL = await saveImageToStorageAndGetUrl(
        image.base64String,
        image.type,
        fileName,
        user.email,
        botId,
      );
      updateBotBubbleIcon(botId, imageURL.downloadURL);
      dispatch(setBubbleIcon(imageURL.downloadURL));
    } catch (err) {
      console.log(err);
      showToastError(err.name);
    }
  };

  const imageReader = async (file) => {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const base64String = fileReader.result.split(',')[1];
          resolve({
            source: file,
            type: file.type,
            name: file.name,
            base64String: base64String,
          });
        };
        fileReader.onerror = (error) => {
          reject({
            type: file.type,
            name: 'Something wrong with your file. Please, try another one.',
          });
        };
        fileReader.readAsDataURL(file);
      } catch (err) {
        reject(err);
      }
    });
  };

  return {
    uploadCompanyLogo,
    uploadBubbleIcon,
  };
};

export default useImageUpload;
