import {
  EnvelopeIcon,
  GlobeAmericasIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { usePopup } from 'hooks/usePopup';
import { Avatar, Contact, PopupWrapper } from 'ui-elements';

export const ClientInfoPopup = () => {
  const clientInfoPopupRef = useRef(null);
  const { clientInfoPopupCoordinate } = useSelector((state) => state.popup);
  const { activeChat } = useSelector((state) => state.chatbot);
  const { closeClientInfoPopup } = usePopup();
  console.log(activeChat);

  const contactItems = [
    activeChat?.email && {
      icon: EnvelopeIcon,
      text: activeChat.email,
    },
    activeChat?.phone && {
      icon: PhoneIcon,
      text: activeChat.phone,
    },
    activeChat?.location && {
      icon: GlobeAmericasIcon,
      text: activeChat.location,
    },
    activeChat?.country && {
      icon: GlobeAmericasIcon,
      text: activeChat.country,
    },
    activeChat?.city && {
      icon: GlobeAmericasIcon,
      text: activeChat.city,
    },
  ];

  const handleClickOutside = (event) => {
    closeClientInfoPopup(clientInfoPopupRef, event);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="fixed"
      ref={clientInfoPopupRef}
      style={{
        top: `${clientInfoPopupCoordinate.y}px`,
        left: `${clientInfoPopupCoordinate.x}px`,
      }}
    >
      <PopupWrapper className="p-4 ">
        <div className="flex items-start gap-3">
          <Avatar active={true} />
          <div className="">
            <header className="flex justify-between gap-1">
              {activeChat?.name ||
                activeChat?.email ||
                (activeChat?.phone && (
                  <h2>
                    {activeChat.name || activeChat.email || activeChat.phone}
                  </h2>
                ))}
            </header>
            <main>
              {contactItems?.map(
                (item) =>
                  item && (
                    <Contact key={item?.text}>
                      {item && <item.icon className="size-5" />}
                      {item?.text}
                    </Contact>
                  ),
              )}
            </main>
          </div>
        </div>
      </PopupWrapper>
    </div>
  );
};
