import {
  CheckIcon,
  LockOpenIcon,
  StarIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { usePopup } from 'hooks/usePopup';
import { Button, PopupShadow } from 'ui-elements';
import { useSelector } from 'react-redux';
import useStripe from 'hooks/API/useStripe';


export const PaywallTrial = () => {
  const { chatbotId } = useSelector((state) => state.chatbot);

  const { closeTrialPaywallPopup, openUpgradePopup } = usePopup();
  const { createCheckoutSession } = useStripe();

  const handleSub = () => {
    createCheckoutSession('All in 1', chatbotId, true);
  }

  return (
    <div className="fixed left-0 top-0 z-[5] flex h-screen w-screen items-center justify-center overflow-y-auto font-medium [&_h2]:text-2xl [&_h3]:text-[20px] [&_h3]:font-bold [&_p]:text-lg [&_p]:font-medium [&_p]:text-del">
      <div className="after:content-'' z-[105] bg-gradient-to-b from-menuBg from-40% to-teal-800 relative grid max-h-[90dvh] w-full max-w-[460px] gap-8 overflow-y-auto rounded-2xl p-5 text-white">
        <div className="relative">
          <h1 className="mb-12 mt-10 text-[27px] font-bold">
          Have doubts?
            <br />
            {/* {strings.startWith}{' '} */}
            <span className="text-purple">Start with a free trial 🎉</span>
          </h1>
          <button
            className="absolute right-0 top-0 z-100"
            onClick={() => {
              closeTrialPaywallPopup();
              openUpgradePopup();
            }}
          >
            <XMarkIcon className="size-6 text-white" />
          </button>
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex gap-2.5">
            <div className="after:content-'' relative -z-[1] flex size-9 min-h-9 min-w-9 items-center justify-center rounded-full bg-main p-1 after:absolute after:left-1/2 after:top-full after:h-[150px] after:w-1.5 after:-translate-x-1/2 after:transform after:bg-main ">
              <CheckIcon className="size-6 text-black" />
            </div>
            <div className="">
              <h2 className="line-through">Join AI Lawyer</h2>
              <p>You successfully started your journey to better and easier life</p>
            </div>
          </div>
          <div className="flex gap-2.5">
            <div className="after:content-'' relative -ml-0.5 flex size-9 min-h-10 min-w-10 items-center justify-center rounded-full border-4 border-[#FFE777] bg-main p-1 after:absolute after:left-1/2 after:top-full after:-z-[1] after:h-[80px] after:w-1.5 after:-translate-x-1/2 after:transform after:bg-main ">
              <LockOpenIcon className="size-6 text-black" />
            </div>
            <div className="">
              <h2>Today</h2>
              <p>No payment now. Enjoy full access to all the premium content for free</p>
            </div>
          </div>
          <div className="flex gap-2.5">
            <div className="after:content-'' relative flex size-9 min-h-9 min-w-9 items-center justify-center rounded-full bg-purple-500 p-1 after:absolute after:bottom-full after:-z-[1] after:h-[40px] after:w-1.5 after:bg-purple-500 ">
              <StarIcon className="size-6 text-white" />
            </div>
            <div className="">
              <h2>24 hours</h2>
              <p>Your trial will convert to discounted monthly price unless cancelled</p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <h3>First 24 hours free</h3>
          <p>
          then $59.99 / month, cancel anytime
          </p>
          <Button
            className="mx-auto mt-4 min-w-[280px] shadow-none text-lg bg-purple-500 rounded-2xl py-3"
            onClick={handleSub}
          >
            Start my free trial
            {/* {isCheckout ? <InputLoading /> : strings.startMyFreeTrial} */}
          </Button>
        </div>
      </div>
      <PopupShadow />
    </div>
  );
};
