import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addDays, format } from 'date-fns';

import {
  addAssistantMessage,
  addCachedChatsHistory,
  addNewChat,
  changeChatAnswerType,
  readChatMessages,
  removeChatFromActiveChats,
  setActiveChat,
  setActiveChatStatus,
  setChatMessages,
  setChatbotId,
  setChats,
} from '../redux/slices/chatbotSlice';
import {
  setChatsLoading,
  setMessagesLoading,
} from '../redux/slices/settingsSlice';
import useChat from './API/useChat';
import useAppNavigation from './useAppNavigation';

export const useChatActions = () => {
  const dispatch = useDispatch();
  const {
    streamChats,
    getChats,
    getChatHistory,
    answerChat,
    setRead,
    updateChat,
  } = useChat();
  const { chats } = useSelector((state) => state.chatbot);

  const { getPath } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];
  const chatId = getPath().pathArray[4];

  const streamAllChats = () => {
    streamChats(chatbotId)
      .then((response) => {
        console.log(response, 'response stream chats');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAllChats = (
    pagination,
    searchByDate,
    searchByValue,
    searchByStatus,
  ) => {
    if (!chats.length) dispatch(setChatsLoading(true));
    const searchParams = {};
    if (searchByStatus) {
      searchParams.status = searchByStatus;
    }
    if (searchByValue) {
      searchParams.value = searchByValue;
    }
    if (searchByDate.from && searchByDate.to) {
      searchParams.date = {
        start: searchByDate?.from,
        end: format(addDays(searchByDate?.to, 1), 'yyyy-MM-dd'),
      };
    }
    getChats(chatbotId, pagination, searchParams)
      .then((response) => {
        console.log('response chats', response);
        dispatch(setChats(response));
        dispatch(setChatsLoading(false));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setChatsLoading(false));
      });
  };

  const getChatMessages = () => {
    dispatch(setMessagesLoading(true));
    getChatHistory(chatId)
      .then((response) => {
        console.log(response, 'response messages');
        dispatch(setChatMessages(response.history));
        dispatch(addCachedChatsHistory({ chatId, history: response.history }));
        dispatch(setMessagesLoading(false));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setMessagesLoading(false));
      });
  };

  const setChatInfo = (id, status, data) => {
    dispatch(setActiveChat({ id, status, ...data }));
  };

  const readAllMessages = (messages) => {
    setRead(chatId, messages)
      .then((response) => {
        console.log(response, 'response read');
        dispatch(readChatMessages(chatId));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sendMessage = (content, lastMessageId) => {
    answerChat(chatbotId, chatId, content, lastMessageId)
      .then((response) => {
        console.log(response, 'response messages');
        dispatch(
          addAssistantMessage({
            id: crypto.randomUUID(),
            content,
            role: 'assistant',
          }),
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateAnswerType = (answer) => {
    updateChat(chatId, { answer })
      .then((response) => {
        dispatch(changeChatAnswerType({ chatId, answer }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateChatStatus = (status) => {
    updateChat(chatId, { status })
      .then((response) => {
        dispatch(removeChatFromActiveChats(chatId));
        dispatch(setChatbotId(''));
        dispatch(setActiveChatStatus('close'));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return {
    streamAllChats,
    getAllChats,
    getChatMessages,
    setChatInfo,
    sendMessage,
    readAllMessages,
    updateAnswerType,
    updateChatStatus,
  };
};
