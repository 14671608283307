import { EnvelopeIcon } from '@heroicons/react/24/outline';
import React from 'react';

import {
  Button,
  Contact,
  GapBlock,
  HeaderWrapper,
  PageContent,
} from 'ui-elements';

export const Support = () => (
  <div className="">
    <PageContent>
      <HeaderWrapper>
        <h2>Support</h2>
      </HeaderWrapper>
      <GapBlock>
        <h3>Contact us by email or leave us a message</h3>
        <Contact>
          <EnvelopeIcon className="size-5" />
          <a href="mailto:hi@aiwidget.com">hi@aiwidget.com</a>
        </Contact>

        <Button>Contact Support</Button>
      </GapBlock>
      <GapBlock>
        <h3>Answers to frequently asked questions</h3>
        <Button>Go to FAQ page</Button>
      </GapBlock>
    </PageContent>
  </div>
);
