import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon, Loader, PopupWrapper, TextInput } from 'ui-elements';
import { googleIcon } from 'utils/iconsHelper';
import { useLogin } from 'hooks/useLogin';
import useAppNavigation from 'hooks/useAppNavigation';

export const LoginPopup = () => {
  const { pageLoading } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.user);
  const { goToChatbots } = useAppNavigation();

  useEffect(() => {
    if (user) {
      goToChatbots();
    }
  }, [user]);

  const { signInWithGoogle } = useLogin();

  return (
    <div className="fixed left-0 top-0 z-20 flex h-screen  w-screen items-center justify-center bg-gradient-to-b from-bodyBgFrom to-bodyBgTo">
      {pageLoading ? (
        <Loader />
      ) : (
        <PopupWrapper className="max-w-96 p-7">
          <h3 className="text-center">Welcome to AI Desk</h3>
          <Button variant="login" onClick={signInWithGoogle}>
            <Icon iconPath={googleIcon} className="min-h-8 min-w-8" />
            <span className="flex-auto">Login with Google</span>
          </Button>
          <p
            className="flex items-center text-grayText before:mr-4 before:block before:h-[1px] before:flex-auto before:bg-grayText before:content-[''] after:ml-4 after:block after:h-[1px] after:flex-auto after:bg-grayText after:content-['']
          "
          >
            or
          </p>
          <p className="text-center font-medium">
            Enter your Email below and sign in with one-time link
          </p>
          <TextInput placeholder="Email" />
          <Button>Get a one-time link</Button>
        </PopupWrapper>
      )}
    </div>
  );
};
