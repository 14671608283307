import { getAuth } from 'firebase/auth';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import _ from 'lodash';

import { convertBase64ToArrayBuffer } from './filesHelper';

const app = Firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
});

export const auth = getAuth(app);
export const functions = getFunctions(app);
export const firestore = getFirestore(app);
export const firebase = Firebase;
export const storage = Firebase.storage();
export const storageRef = storage.ref();

export const saveImageToStorageAndGetUrl = async (
  file,
  type,
  fileName,
  email,
  chatbotId,
) => {
  if (!file || !email) return null;

  const storageUserRef = storageRef.child(`${email}/${chatbotId}`);
  const fileRef = storageUserRef.child(fileName);
  const uint8Array = convertBase64ToArrayBuffer(file);
  const metadata = {
    contentType: type || 'image/svg+xml',
  };
  await fileRef.put(uint8Array, metadata);
  const downloadURL = await fileRef.getDownloadURL();
  return {
    downloadURL,
    fileURL: `${email}/${chatbotId}/${fileName}`,
  };
};

export const deleteFileByDownloadURL = async (downloadURL) => {
  if (!downloadURL) return;
  try {
    const fileRef = storage.refFromURL(downloadURL);
    await fileRef.delete();
  } catch (error) {
    console.error('Error deleting file from Firebase Storage:', error);
  }
};
